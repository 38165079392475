import { graphql } from "gatsby";
import getAllPageComponent  from "./getAllPageComponent";

export const pageQuery = graphql`
    query AllSaleFilterPageTemplate($filterTag: String) {
        markdownRemark(frontmatter: { templateKey: { eq: "all-sale/all-sale-page" } }) {
            html
            frontmatter {
                title
                heading
                eventText
            }
            fields {
                slug
            }
            ...RemoteBannerImage
        }
        items: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {frontmatter: { allSaleIndex: { eq: true }, tags: { in: [$filterTag]  } } }
        ) {
            edges {
                ...AllSaleItem
            }
        }
        highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 2) {
            edges {
                ...EventHighlight
            }
        }
    }`;

const AllSaleFilterPage = getAllPageComponent({
	pageTransformer: (page) => {

		if (page.filterTag) {
			page.title = ` ${page.title} לפיתוח ${page.filterTag}`;
			page.description = ` ${page.description} לפיתוח ${page.filterTag}`;
			page.heading = ` ${page.heading} לפיתוח ${page.filterTag}`;
		}

		return page;
	},
});

export default AllSaleFilterPage;
